var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-page')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"page",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();_vm.$route.name == 'edit-page' ? _vm.updateData(_vm.$route.params.id) : ''}}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.title.$error,
                  },attrs:{"id":"input-1","placeholder":"Enter Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Title is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-2"}},[_c('label',{attrs:{"for":"input-1"}},[_vm._v("Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('ckeditor',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.description.$error,
                  },attrs:{"editor":_vm.editor},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-12",attrs:{"id":"input-group-6","label":"Image [Upload Max File Size : 2MB]","label-for":"input-6"}},[_c('b-form-file',{ref:"image_name",attrs:{"id":"input-6","accept":"image/*","placeholder":"Choose a file or drop it here..."},on:{"change":function($event){return _vm.readFile($event, 'image_name')}}}),(_vm.$route.name == 'edit-page' && _vm.edit.image_name_url)?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.image_name_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.image_name_url)?[_c('img',{ref:"image_name_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.image_name_url,"width":"128px","height":"128px"}})]:_vm._e()],2),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-3","label":"Meta Title","label-for":"input-3"}},[_c('b-form-input',{attrs:{"id":"input-3","placeholder":"Enter Meta Title","maxlength":190},model:{value:(_vm.form.meta_title),callback:function ($$v) {_vm.$set(_vm.form, "meta_title", $$v)},expression:"form.meta_title"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_title)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_title.length !== 190,
                      'badge-danger': _vm.form.meta_title.length === 190,
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_title.length)+" out of 190 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"col-6 position-relative",attrs:{"id":"input-group-5","label":"Meta Keywords","label-for":"input-5"}},[_c('b-form-input',{attrs:{"id":"input-5","maxlength":250,"placeholder":"Enter Meta Keywords"},model:{value:(_vm.form.meta_keywords),callback:function ($$v) {_vm.$set(_vm.form, "meta_keywords", $$v)},expression:"form.meta_keywords"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_keywords)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_keywords.length !== 250,
                      'badge-danger': _vm.form.meta_keywords.length === 250,
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_keywords.length)+" out of 250 chars. ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"position-relative col-12",attrs:{"id":"input-group-4","label":"Meta Description","label-for":"input-4"}},[_c('b-form-textarea',{attrs:{"id":"input-4","maxlength":400,"placeholder":"Enter Meta Description","rows":"3"},model:{value:(_vm.form.meta_description),callback:function ($$v) {_vm.$set(_vm.form, "meta_description", $$v)},expression:"form.meta_description"}}),_c('div',{staticClass:"text-right"},[(_vm.form.meta_description)?_c('p',{staticClass:"badge position-absolute",class:{
                      'badge-success': _vm.form.meta_description.length !== 400,
                      'badge-danger': _vm.form.meta_description.length === 400,
                    },staticStyle:{"top":"4px","right":"13px"}},[_vm._v(" You typed "+_vm._s(_vm.form.meta_description.length)+" out of 400 chars. ")]):_vm._e()])],1)],1),(_vm.$route.name == 'edit-page')?_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.updateData(_vm.$route.params.id)}}},[_vm._v("Update Data")]):_vm._e()],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }